
  .top-content{display: flex;align-items: center; justify-content: space-between;}
  .media-body {flex: 1;}
  .media,.media--reverse {display: flex; align-items: flex-start;}
  .media-body > p { min-height: 200px;}
  .media-body { flex: 1;}
  .media,.media--reverse { display: flex;align-items: flex-start; padding: 0px 26px;margin:0 0 20px;flex-basis: 30em;}
  .top-card .media-heading{font-size: 16px;line-height:16px;color: #ffffff;font-weight: 600;font-family: "Poppins semi-bold"; margin: 0 0 8px;}
  .top-card .floor{line-height:16px;color: #ffffff;font-weight: 300;font-family: "Poppins regular";opacity: 0.6; font-size: 12px; margin: 0 0 8px;}
  .top-card .eth-value{font-size: 16px;line-height:16px;color: #ffffff;font-weight: 600;font-family: "Poppins semi-bold"; }
  .object-value-1{line-height:16px;color: #ffffff;font-weight: 300;font-family: "Poppins regular";opacity: 0.6;font-size: 12px;}
  .object-value-2{color: #46d019;}
  .object-value-2-red{color: #ff0000;}
  .media-body{padding-top: 8px; padding-left: 30px;}
  .top-card .media img{width: 60px;height: 60px;object-fit: cover;border-radius: 15px;}
  .top-card-counter{position: absolute;left: -2%;top: 15%;font-size: 12px;font-family: "Poppins regular";}
  .top-collection-row{display: block;column-count: 3;}
  .top-collection-column{max-width: 100%;z-index: -1;}
  /*responsive*/
  @media only screen and (max-width:1599px){
    .media{padding: 0px 5px;}
  }
@media only screen and (max-width:1399px){
  .top-card-counter{font-size: 16px;left: 0%;}
  .top-card .media-heading{font-size: 12px;}
  .top-card .eth-value{font-size: 12px;}
  .top-card .floor{font-size: 10px;}
  .object-value-1{font-size: 10px;}
  .media-body{padding-left: 10px;} 
  .media{padding: 0px 0px;}
}
@media only screen and (max-width:1199px){
  .top-card-counter{font-size: 14px;left: -2%;}
  .top-card .media-heading{font-size: 11px;}
  .top-card .eth-value{font-size: 12px;}
  .top-card .floor{font-size: 10px;}
  .object-value-1{font-size: 10px;}
  .media-body {padding-left: 10px !important;}
}
@media only screen and (max-width:991px){
  .top-collection-row{column-count: 2;}
  .top-card .media-heading {font-size: 10px;}
}
@media only screen and (max-width:575px){
  .top-card{padding: 0px 10px;}
  .top-card-counter {left: 2%;}
  .top-collection-row{column-count: 1;}
  .custom-container {padding-left: 20px !important;padding-right: 20px !important;}
}
@media only screen and (max-width:320px){
  .media img {width: 50px; align-self: center;}
  .media{padding:unset;}
}
@media only screen and (max-width:280px){
  .media img {width: 35px;align-self: center;}
  .media{width: 100%;}
  .media-body{padding-top: 22px !important;}
}

