.custom-heading h2{display: inline; position: relative; font-size: 33px;line-height: 78px;color: #ffffff;font-weight: 600;font-family: "Poppins semi-bold";}
.custom-heading h2>.collecion-tag{color: #605272;font-family: "Poppins regular";font-weight: normal;}
.custom-heading h2:after{position: absolute;content: "";width: 270px;height:8px;background-image: linear-gradient(to right, #5367c7, #5367c7);bottom: -50%;left: 0%;}
@media only screen and (max-width:1399px){
    .custom-heading h2{font-size: 28px;}
    .custom-heading h2:after{width: 214px;}
}
@media only screen and (max-width:575px){
    .custom-heading h2{font-size: 30px !important;}
    .custom-heading h2:after {width: 142px !important; height: 6px;}
}
@media only screen and (max-width:479px){
    .custom-heading h2 {font-size: 20px !important;}
}
@media only screen and (max-width:319px){
    .custom-heading h2 {font-size: 17px !important;}
}