.footer {padding-top: 100px;}
.footer h2 {font-size: 30px;color: #ffffff;font-weight: 600;font-family: "Poppins semi-bold";margin: 0 0 20px;}
.footer .form-control {font-size: 28px; min-height: 80px;border: none;background-color: #0d153b  !important;border-radius: 20px;border-top-right-radius: 20px !important;border-bottom-right-radius: 20px !important;}
body.light-mode .footer .form-control {font-size: 28px; min-height: 80px;border: none;background-color: #ebebeb !important;border-radius: 20px;border-top-right-radius: 20px !important;border-bottom-right-radius: 20px !important;}
.footer button {position: absolute; z-index: 9999 !important;background-image: none !important; background-color: #5367c7;border: none;height: 50px;top: 50%; transform: translateY(-50%); right: 15px;border-radius: 10px;border-top-left-radius: 10px !important;border-bottom-left-radius: 10px !important;padding: 0px 20px;font-size: 19px;color: #ffffff;font-weight: 500;font-family: "Poppins medium";}
.footer button:hover {background-color: #000;}
.footer p, .footer ul li a{color: #837298 !important;font-family: 'Poppins regular';font-size: 22px !important;font-weight: normal !important;}
.footer ul li {padding: 10px 0px; margin: 0 !important;}
.footer ul li a:hover {color: #fff !important;opacity: 1;transition: all .4s ease;}
.footer-area { border-bottom: 2px solid rgba(255, 255, 255, 0.5);}
.footer ul li a{cursor: pointer;}
.footer .copy-right {text-align: center;font-size: 20px;color: #7e6d92;font-weight: 400;font-family: "Poppins regular";padding: 30px 0px;opacity: 0.7;}
.footer .social-links li a {margin: 0 17px;font-size: 30px;}
.footer .social-links li .instagram, .footer .social-links li .youtube, .footer .social-links li .discord, .footer .social-links li .twitter, .footer .social-links li .medium, .footer .social-links li .telegram, .footer .social-links li .reddit, .footer .social-links li .github, .footer .social-links li .linkedin, .footer .social-links li .facebook{color: #fff !important; opacity: 0.5;cursor: pointer;}
.footer .social-links li .instagram:hover{opacity: 1; color: #c32aa3 !important;}
.footer .social-links li .youtube:hover{opacity: 1; color: #ff0000 !important;}
.footer .social-links li .discord:hover{opacity: 1; color: #5865f2 !important;}
.footer .social-links li .twitter:hover{opacity: 1; color: #1da1f2 !important;}
.footer .social-links li .medium:hover{opacity: 1; color: #02b875 !important;}
.footer .social-links li .telegram:hover{opacity: 1; color: #0088cc !important;}
.footer .social-links li .reddit:hover{opacity: 1; color:  #ff4500 !important;}
.footer .social-links li .facebook:hover{opacity: 1; color: #3b5998 !important;}
.footer .social-links li .linkedin:hover{opacity: 1; color:  #0077b5 !important;}
.footer .social-links li .github:hover{opacity: 1; }
.form-control:focus{color: #fff;box-shadow: none;}
.footer .form-control::placeholder{color: #fff;}
.footer .form-control{color: #ffff;    padding: 0 128px 0 20px;}
.footer .form-control::placeholder{opacity: 0.3;}
.footer  .input-group {max-width: 575px;}
/*responsive*/
@media only screen and (max-width:1599px) {
    .footer p,.footer ul li a {font-size: 24px !important;}
    .footer h2 {font-size: 24px !important;}
    .social-links li a {margin: 0px 46px 0 0;font-size: 35px;}
}
@media only screen and (max-width:1399px) {
    .footer p,.footer ul li a {font-size: 20px !important;}
    .footer h2 {font-size: 20px !important;}
    .footer ul li {padding: 5px 0px;}
    .copy-right {font-size: 15px;}
    .footer button {font-size: 16px;}
    .social-links li a {margin: 0px 40px 0 0;font-size: 32px;}
    .footer .form-control {min-height: 68px;}
}
@media only screen and (max-width: 991px) {

    .footer p,.footer ul li a {font-size: 14px !important;}
    .footer h2 {font-size: 16px !important;}
    .social-links li a {margin: 0px 30px 0 0;font-size: 27px;}
}
@media only screen and (max-width: 767px) {
    .footer h2 {padding-top: 30px;}
}
@media only screen and (max-width: 575px) {
    .footer button{position: absolute !important;right: 10%;padding: 0px 9px;font-size: 14px;}
    .social-links {margin-bottom: 29px !important; display: flex;align-items: center;justify-content: center;}
    .footer .form-control {margin: 0px auto;}
    .footer {padding-top: 19px;}
    .footer .form-control {margin: 0px auto;position: relative;left: 50%;transform: translate(-50%);width: 100%;}
    .footer h2{text-align: center;}
    .footer-area ul,.footer p, .footer ul li a{text-align: center;}
    .social-links li a {margin: 0px 21px 0 0;font-size: 24px;   }
    .footer .copy-right {font-size: 15px;}
    .footer ul.social-links.list-unstyled li {width: 25%;}
}
@media only screen and (max-width: 319px) {
    .social-links li a {margin: 0px 16px 0 0;font-size: 20px;}
}