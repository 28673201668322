.top-collection{padding-top: 30px;padding-bottom: 0;}
.top-collection .tab-area{display: inline-block !important;}
.top-collection .tab-area .nav{background-color: #0d153b ;padding: 10px;border-radius: 20px;}
.top-collection .tab-area  .nav-link{font-size: 16px;letter-spacing: 1px;color: #fff;font-weight: 600;font-family: "Poppins regular";}
.top-collection .nav-pills .nav-link.active, .top-collection .nav-pills .show>.nav-link{background-color: #5367c7 !important; border-radius: 15px;border: none !important; box-shadow: none !important;}
.top-collection .tab-content{color: red;}
.top-collection .top-dropdown{background-color: #0d153b ;display: inline-block;padding: 6px;border-radius: 20px;text-align: end;}
.top-collection .dropdown button,  .top-collection .dropdown button:hover{color: #fff;background-image: none !important;}
.top-collection .dropdown-toggle::after {margin-left: 90px;}
.total-volumn{font-size: 17px;color: #fff;font-weight: normal;margin: 0;}

@media only screen and (max-width:1199px){
    .top-collection .tab-area .nav-link {font-size: 12px;}
}
@media only screen and (max-width:991px){
    .top-collection .tab-area .nav-link {font-size: 10px;}
}
@media only screen and (max-width:991px){
    .top-collection .top-dropdown {padding:2px}
    .top-collection .dropdown-toggle::after { margin-left: 0px;}
    .top-collection .top-collection {padding-top: 0px;}
} 
@media only screen and (max-width:768px){
    .top-collection .top-collection {padding-top: 0px !important;}
}
@media only screen and (max-width:575px){
    .top-collection .top-dropdown {padding: 0px 20px;margin-bottom: 25px;}
    .top-collection .tab-area,.top-dropdown{position: relative;left: 50%;transform: translateX(-50%);}
    .top-collection .nav-pills .nav-link.active, .top-collection .nav-pills .show>.nav-link {border-radius: 8px;}
   
}
@media only screen and (max-width:280px){
    .top-collection .tab-area .nav {
        padding: 7px 0px;
    }
}


