.custom-heading-one {padding: 0;}
.custom-heading-one h2{text-align: center; display: inline-block; position: relative; font-size: 33px;color: #ffffff;font-weight: 600; font-family: "Poppins semi-bold";margin:0 0 15px;}
.custom-heading-one P{margin:0 0 5px;}
.custom-heading-one .heading-block{padding:0 0 25px}
.custom-heading-one .heading-block:after{position: absolute;content: "";width: 250px;height: 10px;background-image: linear-gradient(to right, #5367c7, #5367c7);bottom:0;left: 50%;-o-transform: translateX(-50%);-ms-transform: translateX(-50%);-moz-transform: translateX(-50%);-webkit-transform: translateX(-50%);transform: translateX(-50%);}
@media only screen and (max-width:1399px){
    .custom-heading-one h2 {font-size: 28px;}
    .custom-heading-one h2:after {width: 187px;height: 8px;}
}
@media only screen and (max-width:575px){
    .custom-heading-one h2 {font-size: 30px !important;}
    .custom-heading-one h2:after {width: 147px !important; height: 4px !important;}
}
@media only screen and (max-width:479px){
    .custom-heading-one h2 {font-size: 20px !important;}
}
@media only screen and (max-width:319px){
    .custom-heading-one h2 {font-size: 17px !important;}
}