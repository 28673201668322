@font-face {font-family: 'poppins extra-light'; src: url('./assets/font/poppins-extralight.woff2') format('woff2'),url('./assets/font/poppins-extralight.woff') format('woff');font-weight: 300; font-style: normal;}
@font-face {font-family: 'poppins medium';src: url('./assets/font/poppins-medium.woff2') format('woff2'),url('./assets/font/poppins-medium.woff') format('woff');font-weight: 500;font-style: normal;}
@font-face {font-family: 'poppins bold';src: url('./assets/font/poppins-bold.woff2') format('woff2'),url('./assets/font/poppins-bold.woff') format('woff');font-weight: 700;font-style: normal;}
@font-face {font-family: 'poppins semi-bold';src: url('./assets/font/poppins-semibold.woff2') format('woff2'),url('./assets/font/poppins-semibold.woff') format('woff');font-weight: 700;font-style: normal;}
@font-face {font-family: 'poppins regular';src: url('./assets/font/poppins-regular.woff2') format('woff2'), url('./assets/font/poppins-regular.woff') format('woff');font-weight: 400;font-style: normal;}
body{background-color:#070829;position: relative;}
button , .nav-link , input,.form-control,.custom-blockchain-link,.footer ul li a,.nav-item,.btn,.icon-circle-heart,.custom-card-link,.mettoz a,.blockchains ,.mints ,.react-switch,.content,.react-multi-carousel-list, .learn-more .learn-more-video, .privacy-and-terms a, .how-works-link ul li a, .wallet-connect-area.login-page .login-logo .navbar-brand, .reset-div  {z-index: 8 !important;position: relative;}
.item ,label,.choose-banner-input-group,.items-sidebar,.custom-activity-column, .dropdown-toggle.custom-drop-down-toggle,.custom-card,p,span,h1,h2,h3,h4,h5,h6,i,a,svg,.sidebar-links ul li,.filter-wrapper,.light-checkbox {z-index:8 !important;}
.custom-tab-table,.activity-table,.detail-area{z-index: 10;position: relative;}
canvas {z-index: 1;width: 100%;height: 100%;}
* {scrollbar-width: auto;scrollbar-color: #5367c7 ;}
  *::-webkit-scrollbar {width: 8px;}
  *::-webkit-scrollbar-track {background: transparent;}
  *::-webkit-scrollbar-thumb {background-color: #5367c7;}
  .custom-container{padding-left: 90px;padding-right: 90px;}
  .profile-container{padding-left: 110px;padding-right: 110px;}
  /* .create-nft-container{padding-left: 250px;padding-right: 250px;} */
  .create-container{max-width: 940px;margin: 0px auto;}
  .container-fluid{max-width: 1920px !important;}
  a,a:hover{text-decoration: none;}
  p{margin-bottom: 0px;}
  .row { margin-right: -15px !important; margin-left: -15px !important;}
  body.modal-open >:not(.modal){z-index: 11; background-color: rgba(0, 0, 0, 0.2) !important;-webkit-filter: blur(10px);filter: blur(10px);position: absolute !important; top: 0;bottom: 0; left: 0; right: 0;overflow: hidden;}
  .react-confirm-alert-overlay{backdrop-filter: blur(10px);}
  .loader-is-visible{z-index:1100 !important;}
  .css-4ljt47-MenuList {
    background-color: #1a1226 ;
    font-size: 12px !important;
}
/*responsive*/
@media only screen and (max-width:1399px){
    .custom-container,.create-nft-container {padding-left: 60px;padding-right: 60px;}
}
@media only screen and (max-width:1199px){
  .profile-container,.create-nft-container{padding-left: 30px;padding-right:30px;}
  .custom-container {padding-left: 40px;padding-right: 40px;}
}
@media only screen and (max-width:991px){
    .navbar-toggler i{color: #d32986 !important;}
    .navbar-collapse {background: #281b3f;}
}
@media only screen and (max-width:767px){
  .custom-container {padding-left: 30px;padding-right: 30px;}
}
@media only screen and (max-width:480px){
  .banner .custom-container {padding-left: 12px !important;padding-right: 12px !important;}
}