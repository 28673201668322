.activity-table .table thead td, .activity-table .table thead th {border: none !important;color: #fff;}
.activity-table .table tbody td, .activity-table .table tbody th {border: 1px solid rgb(119, 105, 143);border-left: none !important;border-right: none !important;color: #fff;}
.activity-table .table tbody td:first-of-type{border-left: 1px solid rgb(119, 105, 143) !important;}
.activity-table .table tbody td:last-of-type{border-right: 1px solid rgb(119, 105, 143) !important;}
.activity-table .table{table-layout: fixed;min-width: 1166px;border-spacing: 0px 30px !important;}
.activity-table .table-striped tbody tr {background-color: transparent;cursor: pointer;}
.activity-table .table-striped tbody tr:hover{background-color: #2a2335;}
.activity-table .table .td-start{width: 80px;word-break: break-word;}
.activity-table .table .td-items{width: 330px;word-break: break-word;}
.activity-table .table .td-price{width: 170px;word-break: break-word;}
.activity-table .table .td-quantity{width: 140px;word-break: break-word;}
.activity-table .table .td-from{width: 155px;word-break: break-word;}
.activity-table .table .td-to{width: 155px;word-break: break-word;}
.activity-table .table .td-time{width: 180px;word-break: break-word;}
.activity-table .activity-item .activity-img{width: 60px;height: 60px;border-radius: 5px;overflow: hidden;}
.activity-table .activity-item .activity-img img{width: 100%;height: 100%;object-fit: cover;}
.activity-table .activity-item .activity-data{margin-left: 20px;width: calc(100% - 80px);}
.activity-table .activity-item .activity-data h3{font-size: 14px;line-height: 17px;font-family: 'poppins semi-bold';font-weight: 700;margin: 0 0 5px;}
.activity-table .activity-item .activity-data .username{margin: 0 0 10px;}
.activity-table .activity-item .activity-data .username span{font-size: 12px;line-height: 15px;font-family: 'poppins regular';font-weight: 400;}
.activity-table .activity-item .activity-data .username a{font-size: 12px;line-height: 15px;font-family: 'poppins semi-bold';font-weight: 700;}
.activity-table  a{color: #5367c7 !important;}
.activity-table h4{margin: 0;font-size: 13px;line-height: 17px;font-family: 'poppins semi-bold';font-weight: 700;}
.activity-table .time{font-size: 10px;line-height: 12px;font-family: 'poppins medium';font-weight: 500;color: #6b617a;}
.activity-table .center-align{height: 90px;margin-left: 16px;font-size: 12px;white-space: nowrap;}
