.learn-more{padding-top: 120px;padding-bottom: 0;}
.learn-more img{border-radius: 20px;width: 100%;height: auto;margin-bottom: 70px;}
.learn-more-heading{margin-bottom: 80px;}
.learn-more .learn-more-video { width: 100%; height: 970px; margin: 0 0 70px; }
.learn-more .learn-more-video > div {height: 100%;}
.learn-more .learn-more-video iframe {width: 100%; height: 100%;border-radius: 25px;}
/*responsiveness*/
@media only screen and (max-width:1919px){
    .learn-more .learn-more-video {height: 910px;}
}
@media only screen and (max-width:1799px){
    .learn-more .learn-more-video { height: 800px;}
}
@media only screen and (max-width:1599px){
    .learn-more .learn-more-video {height: 700px;}
}
@media only screen and (max-width:1399px){
    .learn-more .learn-more-video {height: 600px;}
}
@media only screen and (max-width:1199px){
    .learn-more .learn-more-video {height: 500px;}
}
@media only screen and (max-width:991px){
    .learn-more .learn-more-video { height: 400px;}
}
@media only screen and (max-width:767px){
    .learn-more .learn-more-video {height: 290px;margin: 0 0 30px;}
}
@media only screen and (max-width:575px){
    .learn-more {padding-top: 22px;}
    .learn-more .learn-more-video {height: 254px;}
}
@media only screen and (max-width:479px){
    .learn-more .learn-more-video {height: 160px;}
}